import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import head from 'lodash.head';
import Layout from '../components/layout';
import Hero from '../components/hero';
import HighlightPanel from '../components/highlight-panel/';
import IntegrationCollage from '../components/integration-collage';
import SignUpPanel from '../components/sign-up-panel/';
import Footer from '../components/footer';

const HowItWorksPage = () => (
  <StaticQuery
    query={graphql`
      query howItWorksPage {
        allContentfulHowItWorksPage {
          edges {
            node {
              navigationBackgroundColor
              seo {
                seoTitle
                seoKeywords {
                  seoKeywords
                }
                seoDescription {
                  seoDescription
                }
              }
              hero {
                title
                backgroundColor
                headline
                headlineTextColor
                teaser {
                  teaser
                }
                teaserColor
                heroImageAltText
                isHomepageHero
                heroImage {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
              workflowIntegrationPanel {
                backgroundColor
                title
                headline
                headlineColor
                isSimpleHeadline
                textColor
                text {
                  text
                }
                imageMiddleTextTop
                imageBottom
                imageAltText
                images {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
              integrationCollage {
                collageBackgroundColor
                block1ImageAltText
                block1ImageBackgroundColor
                block1Image {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                block2Headline
                block2HeadlineColor
                block2Text
                block2TextColor
                block2BackgroundColor
                block3IntegrationPanel {
                  backgroundColor
                  title
                  headline
                  headlineColor
                  textColor
                  text {
                    text
                  }
                  imageBottom
                  imageAltText
                  image {
                    id
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                }
                block4Headline
                block4TextColor
                block4ImageAltText
                block4BackgroundColor
                block4Image {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                block5Headline
                block5TextColor
                block5BackgroundColor
                block6IntegrationPanel {
                  backgroundColor
                  title
                  headline
                  headlineColor
                  textColor
                  text {
                    text
                  }
                  imageBottom
                  imageAltText
                  image {
                    id
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                }
              }
              dataSecurityPanel {
                backgroundColor
                title
                headline
                headlineColor
                isSimpleHeadline
                textColor
                text {
                  text
                }
                imageMiddleTextTop
                imageBottom
                imageAltText
                images {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
              signUpPanel {
                signUpButton {
                  text
                  textColor
                  backgroundColor
                  url
                }
                backgroundColor
                headline
                headlineColor
                panelHeadline
                panelHeadlineColor
                textBackgroundColor
                imageBackgroundColor
                discounttrialBadge
                discounttrialBadgeTextColor
                discounttrialBadgeBackgroundColor
                imageAltText
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                bulletPointList {
                  id
                  bulletPoints {
                    id
                    text
                    textColor
                    imageAltText
                    image {
                      file {
                        url
                        fileName
                        contentType
                      }
                    }
                  }
                }
              }
              footer {
                id
                links {
                  id
                  externalUrl
                  slug
                  displayName
                }
                logo {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulHowItWorksPage: { edges } }) => {
      const {
        navigationBackgroundColor,
        seo,
        hero,
        workflowIntegrationPanel,
        integrationCollage,
        dataSecurityPanel,
        signUpPanel,
        footer,
      } = head(edges).node;
      return (
        <Layout
          seoTitle={seo.seoTitle}
          seoDescription={seo.seoDescription.seoDescription}
          seoKeywords={seo.seoDescription.seoDescription}
          navigationBackgroundColor={navigationBackgroundColor}
        >
          <Hero node={hero} />
          <HighlightPanel node={workflowIntegrationPanel} />
          <IntegrationCollage node={integrationCollage} />
          <HighlightPanel node={dataSecurityPanel} />
          <SignUpPanel node={signUpPanel} />
          <Footer node={footer} />
        </Layout>
      );
    }}
  />
);

export default HowItWorksPage;
