import React, { Fragment } from 'react';
import Section from '../section';
import IntegrationPanel from '../integration-panel/';
import { integrationCollagePropType } from '../../prop-types';
import integrationCollageStyles from '../integration-collage/integration-collage.module.scss';

const IntegrationCollage = ({
  node: {
    collageBackgroundColor,
    block1ImageBackgroundColor,
    block1Image,
    block1ImageAltText,
    block2BackgroundColor,
    block2Headline,
    block2HeadlineColor,
    block2Text,
    block2TextColor,
    block3IntegrationPanel,
    block4Headline,
    block4TextColor,
    block4ImageAltText,
    block4BackgroundColor,
    block4Image,
    block5Headline,
    block5TextColor,
    block5BackgroundColor,
    block6IntegrationPanel,
  },
}) => (
  <Fragment>
    <Section backgroundColor={collageBackgroundColor}>
      <div className={integrationCollageStyles.container}>
        <div className={integrationCollageStyles.block1And2Container}>
          <div
            className={integrationCollageStyles.block1}
            style={{ backgroundColor: block1ImageBackgroundColor }}
          >
            <img src={block1Image.file.url} alt={block1ImageAltText} />
          </div>
          <div
            className={integrationCollageStyles.block2}
            style={{ backgroundColor: block2BackgroundColor }}
          >
            <h2
              className={integrationCollageStyles.block2Headline}
              style={{ color: block2HeadlineColor }}
            >
              {block2Headline}
            </h2>
            <p className={integrationCollageStyles.block2Text} style={{ color: block2TextColor }}>
              {block2Text}
            </p>
          </div>
        </div>

        <IntegrationPanel node={block3IntegrationPanel} />

        <div className={integrationCollageStyles.block4And5Container}>
          <div
            className={integrationCollageStyles.block4}
            style={{ backgroundColor: block4BackgroundColor }}
          >
            <h2
              className={integrationCollageStyles.block4Headline}
              style={{ color: block4TextColor }}
            >
              {block4Headline}
            </h2>
            <img
              className={integrationCollageStyles.block4Image}
              src={block4Image.file.url}
              alt={block4ImageAltText}
            />
          </div>

          <div
            className={integrationCollageStyles.block5}
            style={{ backgroundColor: block5BackgroundColor }}
          >
            <h2
              className={integrationCollageStyles.block5Headline}
              style={{ color: block5TextColor }}
            >
              {block5Headline}
            </h2>
          </div>
        </div>
        <IntegrationPanel node={block6IntegrationPanel} />
      </div>
    </Section>
  </Fragment>
);

IntegrationCollage.propTypes = {
  node: integrationCollagePropType.isRequired,
};

export default IntegrationCollage;
