import React, { Fragment } from 'react';
import classnames from 'classnames';
import integrationPanelStyles from '../integration-panel/integration-panel.module.scss';
import { highlightPanelPropType } from '../../prop-types/';

/* Allows different classNames to be added to certain elements
   depending on the value of the 'imageBottom' boolean - 
   to position images correctly according to the designs */
const getElementAlignmentModifierClass = (baseClass, imageBottom) =>
  imageBottom
    ? integrationPanelStyles[`${baseClass}ImageBottom`]
    : integrationPanelStyles[`${baseClass}ImageRight`];

const IntegrationPanel = ({
  node: {
    backgroundColor,
    headline,
    headlineColor,
    text,
    textColor,
    imageBottom,
    image,
    imageAltText,
  },
}) => (
  <Fragment>
    <div
      className={classnames(
        integrationPanelStyles.container,
        getElementAlignmentModifierClass('container', imageBottom)
      )}
      style={{ backgroundColor }}
    >
      <div
        className={classnames(
          integrationPanelStyles.textContainer,
          getElementAlignmentModifierClass('textContainer', imageBottom)
        )}
      >
        <h2
          className={classnames(
            integrationPanelStyles.headline,
            getElementAlignmentModifierClass('headline', imageBottom)
          )}
          style={{ color: headlineColor }}
        >
          {headline}
        </h2>
        <p
          className={classnames(
            integrationPanelStyles.text,
            getElementAlignmentModifierClass('text', imageBottom)
          )}
          style={{ color: textColor }}
        >
          {text.text}
        </p>
      </div>
      <div
        className={classnames(
          integrationPanelStyles.imageContainer,
          getElementAlignmentModifierClass('imageContainer', imageBottom)
        )}
      >
        <img
          className={classnames(
            integrationPanelStyles.image,
            getElementAlignmentModifierClass('image', imageBottom)
          )}
          src={image.file.url}
          alt={imageAltText}
        />
      </div>
    </div>
  </Fragment>
);

IntegrationPanel.propTypes = {
  node: highlightPanelPropType.isRequired,
};

export default IntegrationPanel;
